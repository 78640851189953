<template>
  <div class="marquee-container" ref="container">
    <div
      class="marquee-content"
      :style="{ animationDuration: animationDuration + 's' }"
      ref="content"
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    speed: {
      type: Number,
      default: 50,
    },
  },
  data() {
    return {
      animationDuration: 0,
    };
  },
  mounted() {
    this.calculateDuration();
  },
  methods: {
    calculateDuration() {
      const containerWidth = this.$refs.container.offsetWidth;
      const contentWidth = this.$refs.content.offsetWidth;

      if (contentWidth > containerWidth) {
        this.animationDuration = contentWidth / this.speed;
      } else {
        this.animationDuration = 0;
      }
    },
  },
};
</script>

<style scoped>
.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%;
  height: 1.5rem;
}

.marquee-content {
  display: inline-block;
  position: absolute;
  animation: marquee linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(20%);
  }
  to {
    transform: translateX(-90%);
  }
}

.marquee-content:hover {
  animation-play-state: paused;
}
</style>

<template>
  <div class="schedule" @dragover="disableDragendAnimation">
    <b-row>
      <b-col md="4">
        <b-form-group
          label="Manager"
          label-for="managerScheduler"
        >
          <v-select
            id="managerScheduler"
            v-model="ManagerScheduler"
            label="title"
            :options="managerSchedulerOption"
            placeholder=""
            name="managerScheduler"
          />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group
          label="Location"
          label-for="location"
        >
          <v-select
            id="location"
            v-model="Location"
            label="title"
            :options="locationOption"
            placeholder=""
            name="location"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          label="Action Type Class"
          label-for="actionType"
        >
          <v-select
            id="actionType"
            v-model="ActionTypeClass"
            label="title"
            :options="actionTypeOption"
            placeholder=""
            name="actionType"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="caseObj">
      <b-col>
        <div style="width: fit-content">
          <router-link
            :to="'/cases/' + caseObj.CaseID + '/detail/ACTIONS'"
          >
            <h3 style="text-decoration: underline">
              Case {{ caseObj.FileNumber }}
            </h3>
          </router-link>
        </div>
      </b-col>
    </b-row>
    <div>
      <div class="sc-main-box" :style="{width: '85%'}">
        <div class="week-pagination manager">
          <div class="arrow-wrapper" @click="changeWeek(false)">
            <feather-icon
              icon="ChevronsLeftIcon"
              size="21"
              style="color: white"
            />
          </div>
          <div class="wrap-datepicker">
            <b-form-datepicker
              v-model="DatePicker"
              class="datepicker"
              button-variant="primary"
              button-only
              size="sm"
            />
          </div>
          <span class="current-week">Week of: {{ settingData.startDate + " - " + settingData.endDate }}</span>
          <div class="arrow-wrapper" @click="changeWeek(true)">
            <feather-icon
              icon="ChevronsRightIcon"
              size="21"
              style="color: white"
            />
          </div>
        </div>

        <div style="display: flex; overflow: hidden">
          <div class="sc-rows" :style="{width: '10vw', marginRight: '0.1rem'}">
            <div class="sc-rows-scroll" :style="{width: '100%'}">
              <div :style="{height: '4.1rem', padding: '1.1rem', borderBottom: '1px solid white'}">Assignments</div>
              <div id="scroll-output">
                <div
                  v-for="(row, index) in scheduleData"
                  :key="index"
                  :class="'timeline title'"
                  :style="{'height': settingData.rowH + 'rem', 'padding-top': '0px', 'margin-left' : '0', borderTop: '1px solid white', cursor: 'pointer'}"
                  @click="$emit('row-click-event', row)"
                >
                  <div>
                    <div class="pb-0 pl-1">{{ row.title }}</div>
                    <div class="pl-1">{{ row.city }}{{ row.city && row.state ? ", " : "" }}{{ row.state }}</div>
                    <div class="d-flex pl-1 justify-content-between">
                      <div>{{ row.workTime }}</div>
                      <div v-if="getCurrentScopes.indexOf('st2.viewcalculatedtravel') !== -1" class="pr-1 distance-time">
                        {{ row.distanceTime | roundUpToNearestHour }}
                      </div>
                    </div>
                    <div class="pl-1">
                      <Marquee :text="getUserTags(row)" :speed="40" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="table-block" class="sc-main-scroll" :style="{width: 'fit-content'}">
            <div class="sc-main">
              <div
                class="timeline"
                :style="{height: '4rem', background: '#6187ae'}"
              >
                <div
                  v-for="n in dateCnt"
                  :key="n"
                  class="sc-time"
                  :style="timelineDate(n - 2) ?
                    {background: 'blue', width: dateDivW + 'vw', fontWeight: 'bold', fontSize: '1rem'} :
                    {width: dateDivW + 'vw', fontWeight: 'bold', fontSize: '1rem'}"
                >
                  <div v-if="timelineDate(n - 2)">
                    <div class="today-date">{{ timelineDate(n - 2) }}</div>
                    <div class="today-date">{{ getHeaderDate(n - 2) }}</div>
                  </div>
                  <div v-else>
                    {{ getHeaderDate(n - 2) }}
                  </div>
                </div>
              </div>

              <div class="scroll-container" id="scroll-input">
                <div
                  v-for="(row, index) in scheduleData"
                  :key="index"
                  :class="'timeline'"
                  :style="{'height': settingData.rowH + 'rem'}"
                >
                  <UnitBlock
                    v-for="n in unitCnt"
                    :key="'unit' + n"
                    :row-index="index"
                    :key-index="n"
                    :dayOff="isDayOff(row, n)"
                    :width="settingData.unitDivW + 'vw'"
                    @set-dragenter-row-and-index="setDragenterRowAndIndex"
                  ></UnitBlock>

                  <ReservedBlock
                    v-for="(detail, keyNo) in row.schedule"
                    :style="detail.hidden ? {'display': 'none'} : ''"
                    :key="'res' + keyNo"
                    :actionID="Number(ActionID)"
                    :schedule-detail="detail"
                    :row-detail="row"
                    :index="detail.index"
                    :occupied="detail.occupied"
                    :row-index="index"
                    :key-no="keyNo"
                    :start-text="detail.start"
                    :end-text="detail.end"
                    :unit-width="settingData.unitDivW"
                    :border-width="settingData.borderW"
                    :min-date="settingData.startDate"
                    :unit="settingData.unit"
                    :dragenter-row-index="dragenterRowIndex"
                    :dragenter-column-index="dragenterColumnIndex"
                    @drag-change-week="dragChangeWeek"
                    @set-dragenter-row-and-index="setDragenterRowAndIndex"
                    @move-schedule-data="moveScheduleData"
                    @edit-event="$emit('edit-event', detail.start, detail.end)"
                    @click-event="$emit('click-event', detail.start, detail.end, detail.text, detail.data)"
                    @cancel-assignment="cancelAssignment"
                    @change-assignment="changeAssignment"
                    @multiple-assignment="multipleAssignment"
                    @change-index="changeIndex"
                  >{detail.index}</ReservedBlock>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br class="clear" />
    </div>
  </div>
</template>

<script>
import SchedulesService from "./assetsSchedules"
import UnitBlock from "./UnitBlock"
import ReservedBlock from "./ReservedBlock"
import {BCol, BFormDatepicker, BFormGroup, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import APIService, {axiosInc} from "@core/utils/APIService";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {mapGetters} from "vuex";
import Marquee from "@/views/schedule/Marquee";

const apiService = new APIService();
const ScheduleService = new SchedulesService();

export default {
  components: {
    BRow,
    BCol,
    UnitBlock,
    ReservedBlock,
    BFormGroup,
    vSelect,
    BFormDatepicker,
    Marquee
  },
  filters: {
    roundUpToNearestHour(totalMinutes) {
      if (!totalMinutes) return "";
      return Math.ceil(totalMinutes / 60) + " h";
    }
  },
  data() {
    return {
      ActionID: 0,
      UserID: 0,
      caseObj: null,
      actionObj: null,
      newAssignment: null,
      inputDate: new Date(),
      scheduleData: [],
      settingData: {
        startDate: "2020/04/20",
        endDate: "2020/04/26",
        unit: 1440,
        borderW: 1,
        timeDivH: 3,
        unitDivW: 8,
        titleDivW: 10,
        rowH: 6.4
      },
      dateDivW: 0,
      dateCnt: 0,
      unitCnt: 0,
      dragenterRowIndex: null,
      dragenterColumnIndex: null,
      oldUnitShift: 0,

      templateName: "",
      isLoad: true,
      oldTarget: null,
      ManagerScheduler: "",
      Location: "",
      ActionTypeClass: "",
      managerSchedulerOption: [],
      actionTypeOption: [],
      usersOptions: [],
      locationOption: [],
      membersUsers: [],
      Assignments: [],
      dayOffs: [],

      oldRequest: null,
      oldLocation: "",
      DatePicker: "",
    };
  },
  computed: {
    ...mapGetters({
      getCurrentScopes: "scopes/getCurrentScopes",
    })
  },
  watch: {
    DatePicker(val) {
      this.inputDate = val;
      this.dataPickerChange(val)
      this.getCurrentWeek(val);
      this.getUserSchedule();
    },
    ManagerScheduler(val) {
      this.Location = "";
      this.scheduleData = [];
      this.locationOption = [];
      if (val) {
        this.getManagerMembers(val)
      }
    },
    Location(val, oldVal) {
      this.oldLocation = oldVal;
      this.Assignments = [];
      if (val) {
        const membersUser = this.membersUsers.filter(i => i.location === val)[0];
        if (!membersUser) {
          this.Assignments.push({...this.ManagerScheduler, schedule: []});
        } else {
          membersUser.userMemberIDs.map(item => {
              let i = {}
              i = {...i, ...this.managerSchedulerOption.filter(i => i.value === item)[0]}
              i = {...i, ...this.usersOptions.filter(i => i.value === item)[0]}
            this.Assignments.push(i)
          })
        }
      } else {
        this.Assignments.push({...this.ManagerScheduler, schedule: []});
      }
    },
    Assignments(val) {
      this.scheduleData = [];
      if (val && val.length) {
        if (val[0].value !== this.ManagerScheduler.value) this.scheduleData.push({...this.ManagerScheduler, schedule: []});
        val.map(item => {
          this.scheduleData.push({...item, schedule: []})
        })
      }
      if (this.oldLocation && this.Location && this.oldLocation !== this.Location && this.scheduleData.length) {
        this.scheduleData[0].schedule.push(this.oldTarget)
      }
      this.getUserSchedule();
    },
    ActionTypeClass() {
      this.getUserSchedule();
    },
    caseObj(val) {
      if (val) {
        this.getManagerMembers(this.ManagerScheduler)
      }
    }
  },
  async mounted() {
    this.getTimeOff();
    await this.getUsers();
    this.ActionID = this.$route.params.actionID;
    this.UserID = localStorage.getItem("UID");
    this.templateName = this.$route.name;
    if (this.ActionID) {
      this.loadAction();
    }
    const scrollInput = document.getElementById('scroll-input');
    const scrollOutput = document.getElementById('scroll-output');
    const _this = this;
    scrollInput.addEventListener('scroll', function(e) {
      scrollOutput.scrollTop = scrollInput.scrollTop
      if (_this.verticalMoveCard) {
        _this.verticalMoveCard(e)
      }
    });
  },
  created() {
    this.loadActionTypes();
    this.getCurrentWeek();
    this.dateCnt =
        ScheduleService.getDateDiff(
            new Date(this.settingData.startDate),
            new Date(this.settingData.endDate)
        );
    let oneDayCnt = 1;
    this.unitCnt = this.dateCnt;
    this.dateDivW =
        this.settingData.unitDivW * oneDayCnt +
        (oneDayCnt - this.settingData.borderW);
  },
  methods: {
    showToast(variant, position, timeout, data) {
        this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Notification',
                icon: 'InfoIcon',
                text: data,
                variant,
            },
          },
          {
              position,
              timeout,
          })
    },

    loadAction() {
      apiService
          .get("action/" + this.ActionID + "/detail")
          .then(res => {
            this.actionObj = res.data;
            this.inputDate = res.data.ScheduledDate ? res.data.ScheduledDate : new Date();
            this.getCurrentWeek(res.data.ScheduledDate);
            this.getCase(res.data.CaseID);
          })
    },

    getCase(CaseID) {
      apiService
          .get("case/" + CaseID + "/detail")
          .then(res => {
            this.caseObj = res.data;
            const UID = localStorage.getItem("UID");
            this.ManagerScheduler = this.managerSchedulerOption.filter(i => i.value === Number(UID))[0];
            if (this.templateName !== "manager-scheduler-action-view") this.fillAssignment();
          })
    },

    loadClient(ClientId) {
      apiService
          .get("client/" + ClientId + "/detail")
          .then((res) => {
            this.ManagerScheduler = this.managerSchedulerOption.filter(i => i.value === res.data.AMUserID)[0];
            this.fillAssignment();
          });
    },

    getManagerScheduler() {
      apiService
          .get("user-team-member/manager")
          .then( res => {
            this.managerSchedulerOption = res.data.map(item => {
              const user = this.usersOptions.filter(i => i.value === item.userManagerID)[0]
              if (user) return {
                title: user.title,
                value: user.value,
                locations: item.locations,
                city: item.city,
                state: item.state,
                IsSIU: user.IsSIU,
                isNYSIF: user.isNYSIF,
                IsUSP: user.IsUSP,
                IsEDR: user.IsEDR,
                IsNotary: user.IsNotary,
                IsHybrid: user.IsHybrid,
                IsVeteran: user.IsVeteran,
                workTime: user.workTime
              }
            }).sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
            this.managerSchedulerOption = this.managerSchedulerOption.filter(i => !!i)
            if (this.UserID) {
              this.managerSchedulerOption = this.managerSchedulerOption.filter(i => !!i)
              this.ManagerScheduler = this.managerSchedulerOption.filter(i => i.value === Number(this.UserID))[0]
              if (!this.ManagerScheduler) {
                this.ManagerScheduler = this.usersOptions.filter(i => i.value === Number(this.UserID))[0]
                this.managerSchedulerOption.push(this.ManagerScheduler)
              }
            }
          })
    },

    async getUsers() {
      const response = await apiService
          .get('users/User,Investigator?max=10000"')
          .then(res => res.data)
          .catch(error => error)
      if (response) {
        this.usersOptions = response.Users.map(d => ({
          title: d.LastName + ", " + d.FirstName,
          value: d.UserAccountID, city: d.City, state: d.State,
          IsSIU: d.IsSIU,
          isNYSIF: d.isNYSIF,
          IsUSP: d.IsUSP,
          IsEDR: d.IsEDR,
          IsNotary: d.IsNotary,
          IsHybrid: d.IsHybrid,
          IsVeteran: d.IsVeteran,
          workTime: d.IsPartTime ? "PT" : d.IsFullTime ? "FT" : ""
        }));
        this.getManagerScheduler();
      }
    },

    async getManagerMembers(val) {
      apiService
          .get('user-team-member/' + val.value)
          .then(res => {
            if (val.locations && val.locations.length) this.locationOption = val.locations.sort();
            this.membersUsers = res.data;
            if(this.caseObj && this.caseObj.CaseStateCode) {
              this.Location = this.caseObj.CaseStateCode;
            }
            this.Assignments.push({...this.ManagerScheduler, schedule: []});
          })
    },

    loadActionTypes() {
      apiService
          .get("action-class")
          .then((response) => {
            this.actionTypeOption = response.data.map(item => {
              return {
                title: item.name,
                value: item.id
              }
            }).sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));
          });
    },

    getTimeOff() {
      apiService
          .get("day-off")
          .then( res => {
            if (res) {
              this.dayOffs = res.data;
            }
          })
    },

    fillAssignment() {
      this.oldTarget = this.newAssignment = {
        isEditable: true,
        hidden: false,
        index: 0,
        actionFileNumber: this.actionObj.ActionFileNumber,
        subject: this.caseObj.Branch.Client.Subject.LastName + ", " + this.caseObj.Branch.Client.Subject.FirstName,
        assignment: {
          assignmentID: 1
        },
        actionType: this.actionObj.ActionTypeName,
        caseDueDate: null,
        actionID: this.actionObj.ActionID,
        start: ScheduleService.getScheduleDate(this.actionObj.ScheduledDate ? this.actionObj.ScheduledDate : new Date(), true, !this.actionObj.ScheduledDate),
        end: ScheduleService.getScheduleDate(this.actionObj.ScheduledDate ? this.actionObj.ScheduledDate : new Date(), false),
      }
    },

    getCurrentWeek(val) {
      const currFirst = val ? new Date(val) : new Date();
      const currLast = val ? new Date(val) : new Date();
      const day = currFirst.getDay()
      this.firstData = currFirst.getDate() - (day ? day - 1 : 6);
      this.lastData = this.firstData + 6;
      this.settingData.startDate = ScheduleService.dateFormat(new Date(currFirst.setDate(this.firstData)));
      this.settingData.endDate = ScheduleService.dateFormat(new Date(currLast.setDate(this.lastData)));
    },

    changeWeek(next) {
      if (this.disabledPagination) return;
      const currFirst = new Date(this.inputDate);
      const currLast = new Date(this.inputDate);
      this.firstData = next ? this.firstData + 7 : this.firstData - 7;
      this.lastData = next ? this.lastData + 7 : this.lastData - 7;
      this.settingData.startDate = ScheduleService.dateFormat(new Date(currFirst.setDate(this.firstData)));
      this.settingData.endDate = ScheduleService.dateFormat(new Date(currLast.setDate(this.lastData)));
      if (this.oldTarget) {
        this.oldTarget.start = ScheduleService.getScheduleDate(new Date(this.oldTarget.start).setDate(next ? new Date(this.oldTarget.start).getDate() + 6 : new Date(this.oldTarget.start).getDate() - 8));
      }
      this.getUserSchedule();
    },

    dragChangeWeek(way, rowIndex, keyNo) {
      this.oldTarget = this.scheduleData[rowIndex].schedule[keyNo];
      this.oldTarget.isEditable = true;
      if (this.isLoad) {
        this.changeWeek(way === "right")
      }
    },

    setDragenterRowAndIndex(rowIndex, currentIndex) {
      this.dragenterRowIndex = rowIndex;
      this.dragenterColumnIndex = currentIndex;
    },

    disableDragendAnimation(e) {
      e.preventDefault();
    },

    timelineDate(n) {
      const date = this.getHeaderDate(n)
      if (date === ScheduleService.dateFormatter(new Date())) {
        return "Today"
      } else {
        return null
      }
    },

    isDayOff(row, n) {
      const userDayOffs = this.dayOffs.filter(i => i.userId === row.value);
      let dayOff = false;
      if (userDayOffs.length) {
        const checkedDay = ScheduleService.dateFormatToSort(this.settingData.startDate, n);
        userDayOffs.map(item => {
          const startDate = ScheduleService.dateFormatToSort(item.startDate)
          const endDate = ScheduleService.dateFormatToSort(item.endDate)
          if (startDate <= checkedDay && endDate >= checkedDay) {
            dayOff = true
          }
        })
      }
      return dayOff
    },

    getHeaderDate(n, includeTime = false) {
      let startDate = ScheduleService.addDays(new Date(this.settingData.startDate), n);
      if (includeTime) return ScheduleService.datetimeFormatter(startDate);
      return ScheduleService.dateFormatter(startDate);
    },

    drawCard(newStartDatetime) {
      const stackOfCards = this.scheduleData[this.dragenterRowIndex].schedule
          .filter(i => i.start.substring(0, 10) === newStartDatetime.substring(0, 10) && !i.hidden)
      stackOfCards.map((item, index) => {
        if (this.oldTarget && this.oldTarget.start.substring(0, 10) === item.start.substring(0, 10)) {
          if (item.isEditable) {
            item.index = stackOfCards.length
            item.occupied = stackOfCards
          } else {
            item.index = stackOfCards.length - 1
            item.occupied = item.occupied.filter(i => !i.isEditable)
          }
        } else {
          item.index = index
        }
      })
    },

    dataPickerChange(val) {
      if (this.oldTarget) {
        this.oldTarget.start = ScheduleService.getScheduleDate(val, true);
        this.oldTarget.end = ScheduleService.getScheduleDate(val, false);
      }
    },

    verticalMoveCard(val) {
      const unitHeight = Math.round(val.target.scrollHeight / val.target.childElementCount)
      const unitShift = Math.round((val.target.scrollTop) / unitHeight)
      const displayCount = Math.trunc((val.target.clientHeight / unitHeight) - 2)
      if (this.oldUnitShift === unitShift) return;

      let lastPosition = 0;

      if (this.oldTarget) {
        this.scheduleData.map((row, index) => {
          if (row.schedule.filter(i => i.isEditable).length > 0) lastPosition = index || 1
        })
        this.scheduleData.map(row => {
          row.schedule = row.schedule.filter(i => !i.isEditable)
          return row;
        })
        let shifting = (unitShift - this.oldUnitShift) + lastPosition;
        shifting = Math.min((displayCount + unitShift), shifting, val.target.childElementCount - 1);
        shifting = Math.max(shifting, unitShift + 1);

        const stackOfCards = this.scheduleData[shifting].schedule.filter(i => i.start.substring(0, 10) === this.oldTarget.start.substring(0, 10))
        stackOfCards.push(this.oldTarget);
        this.oldTarget.occupied = stackOfCards
        this.oldTarget.index = stackOfCards.length
        this.scheduleData[shifting].schedule.push(this.oldTarget)
      }
      this.oldUnitShift = unitShift;
    },

    moveScheduleData(rowIndex, keyNo, unitCnt) {
      let targetData = this.scheduleData[rowIndex].schedule[keyNo];
      if (this.oldTarget) {
        targetData = this.oldTarget;
      }
      if (targetData) {
        let newStartDatetime = this.getHeaderDate(unitCnt - 2, true);
        let newEndDatetime = this.getHeaderDate(unitCnt - 1, true);
        if (unitCnt !== 0) {
          targetData.isChangingDate = targetData.start !== newStartDatetime;
          targetData.start = newStartDatetime;
          targetData.end = newEndDatetime;
        }
        if (rowIndex !== this.dragenterRowIndex && this.scheduleData[this.dragenterRowIndex]) {
          this.scheduleData[this.dragenterRowIndex].schedule.push(targetData);
          this.scheduleData[rowIndex].schedule.splice(keyNo, 1);
        }
        this.drawCard(newStartDatetime)
        this.clearData()
      }
    },

    clearData() {
      this.scheduleData.map(item => {
        item.schedule = item.schedule.filter(i => !i.hidden)
      })
    },

    isItDouble(val, preLoad) {
      return preLoad.filter(i => ((i.assignment.assignmentID !== val.assignment.assignmentID) &&
          (i.actionID === val.actionID))).length > 0;
    },

    clearScheduleData() {
      if (this.oldTarget) {
        this.oldTarget.index = 0
        this.oldTarget.occupied = [this.oldTarget]
      }
      this.scheduleData.map(item => {
        if (this.oldTarget && this.oldTarget.assignment.assignmentID) {
          const index = item.schedule.map(e => e.assignment.assignmentID).indexOf(this.oldTarget.assignment.assignmentID);
          if (index === -1) {
            item.schedule = []
          } else {
            let delItem = null;
            item.schedule.map((i, index) => {
              if (i.assignment.assignmentID !== this.oldTarget.assignment.assignmentID) {
                i.hidden = true
                delItem = index
              }
              return i;
            })
            if (delItem !== null) item.schedule.splice(delItem, 1)
          }
        } else {
          item.schedule = []
        }
        return item
      })
    },

    sortScheduleData() {
      const _this = this;
      setTimeout(() => {
        if (_this.newAssignment) {
          let index = 0;
          _this.scheduleData[0].schedule.map(item => {
            if (item.start.substring(0, 10) === _this.newAssignment.start.substring(0, 10)) {
              index++
              _this.newAssignment.occupied.push(item)
            }
          })
          _this.newAssignment.index = index;
          _this.scheduleData[0].schedule.push(_this.newAssignment)
        } else if (_this.oldTarget) {
          let isImplement = false
          _this.scheduleData.map(row => {
            if (row.schedule.filter(i => i.assignment.assignmentID === _this.oldTarget.assignment.assignmentID).length > 0) {
              isImplement = true
              row.schedule.map((item, index) => {
                const occupied = row.schedule.filter(i => (i.start.substring(0, 10) === item.start.substring(0, 10) && !i.hidden));
                item.occupied = occupied
                if (item.isEditable) {
                  item.index = occupied.length
                } else {
                  item.index = occupied.length - 1;
                }
                return item;
              })
            }
            return row;
          })
          if (!isImplement) this.scheduleData[0].schedule.push(_this.oldTarget)
        }
        _this.isLoad = true;
        _this.newAssignment = null;
      }, 600)
    },

    getUserSchedule() {
      const signal = axiosInc.CancelToken.source();
      const request = { cancel: signal.cancel, msg: "Loading..." };
      if (this.oldRequest) this.oldRequest.cancel(this.oldRequest.msg);
      this.oldRequest = request;
      if (!this.ManagerScheduler || !this.ManagerScheduler.value || !this.Location) return;
      this.isLoad = false;
      this.clearScheduleData();
      this.refreshDistanceTime();

      let startDate = ScheduleService.addDays(new Date(this.settingData.startDate), -2);
      startDate = ScheduleService.dateFormatToSort(startDate);

      let patch = `schedule/manager-user-actions?managerId=${this.ManagerScheduler.value}&StartDate=${startDate}&EndDate=${this.settingData.endDate}&Location=${this.Location}`
      if (this.ActionTypeClass) {
        patch = patch + "&ActionClassId=" + this.ActionTypeClass.value
      }

      apiService.get(patch, {cancelToken: signal.token}).then(res => {
        if (!res) return;
        this.oldRequest = null;
        const preLoad = res.data;

        let out = res.data.sort(ScheduleService.byField("assignment.assignmentID"))
        out = out.sort(ScheduleService.byField("approvedByManagerID", false))
        out = out.sort(ScheduleService.byField("caseClosedDate"))

        out.map(item => {
          if (item) {
            this.scheduleData.map((scheduleItem, index) => {
              if (scheduleItem.value === item.assignment.investigatorID &&
                  (!this.oldTarget || !this.oldTarget.reAssignment || this.oldTarget.oldAssignmentID !== item.assignment.assignmentID )) {

                const occupied = scheduleItem.schedule.filter(i => i.start.substring(0, 10) === ScheduleService.getScheduleDate(item.scheduledDate, true).substring(0, 10) && !i.hidden)
                occupied.push(item)

                if (new Date(item.scheduledDate) >= new Date(this.settingData.startDate)) {
                  const obj = {
                    isEditable: !this.newAssignment && !this.oldTarget,
                    isDouble: this.isItDouble(item, preLoad),
                    isClosed: !!item.approvedByManagerID || !!item.caseClosedDate,
                    hidden: false,
                    index: occupied.length - 1,
                    occupied,
                    actionFileNumber: item.actionFileNumber,
                    subject: item.subject,
                    hold: item.hold,
                    mustGo: item.mustGo,
                    assignment: {
                      assignmentID: item.assignment.assignmentID,
                      investigatorID: item.assignment.investigatorID,
                      distanceToMiles: item.assignment.distanceToMiles,
                      distanceFromMiles: item.assignment.distanceFromMiles,
                    },
                    actionType: item.actionType,
                    caseDueDate: item.caseDueDate,
                    actionID: item.actionID,
                    start: ScheduleService.getScheduleDate(item.scheduledDate, true),
                    end: ScheduleService.getScheduleDate(item.scheduledDate, false),
                  }
                  this.scheduleData[index].schedule.push(obj);
                }

                if (item.actionMinutes && new Date(item.scheduledDate) < new Date(this.settingData.endDate)) {
                  this.scheduleData[index].distanceTime = (this.scheduleData[index].distanceTime || 0) + item.actionMinutes;
                }
              }
            })
          }
        })
        this.sortScheduleData();
      })
    },

    refreshDistanceTime() {
      this.scheduleData.map(i => i.distanceTime = 0);
    },

    cancelAssignment() {
        setTimeout(() => {
            this.oldTarget = null;
            this.getUserSchedule()
        }, 700)
    },

    changeIndex(start, investigatorID, actionID) {
        let rowIndex = 0;
        let itemIndex = 0;
        this.scheduleData.map((row, index)=> {
            if (row.value === investigatorID) rowIndex = index
        })
        const cardScope = this.scheduleData[rowIndex].schedule.filter(i => (i.start.substring(0, 10) === start.substring(0, 10) && !i.hidden))
        if (cardScope.filter(i => i.assignment.assignmentID === 1).length) {
            this.showToast('danger', 'top-center', 4000, 'Cannot change card order during an edit.  Move edit card from this cell first or complete the edit.');
            return
        }
        this.scheduleData[rowIndex].schedule.map(item => {
            if (item.start.substring(0, 10) === start.substring(0, 10) && !item.hidden) {
                item.index = itemIndex < cardScope.length - 1 ? itemIndex - 1 : itemIndex - 1;
                item.occupied = cardScope
                if (item.actionID === actionID) {
                    item.index = cardScope.length - 1
                }
                return item
            }
        })
    },

    changeAssignment(obj, oldInvestigatorId = null) {
      this.scheduleData.map(row => {
        row.schedule.map(item => {
          if (item.actionID === obj.actionID && item.index === obj.index &&
              item.start === obj.start && item.assignment.assignmentID === obj.assignment.assignmentID) {
            item.oldAssignmentID = item.assignment.assignmentID;
            item.assignment.assignmentID = 1;
            item.previousInvestigatorId = oldInvestigatorId;
            item.index = row.schedule.filter(i => (i.start.substring(0, 10) === item.start.substring(0, 10) && !i.hidden)).length;
            item.reAssignment = true;
            this.oldTarget = item;
          } else {
            item.isEditable = false;
            item.index = row.schedule.filter(i => (i.start.substring(0, 10) === item.start.substring(0, 10) && !i.hidden)).length - 1;
          }
          return item;
        })
      })
    },

    multipleAssignment(obj, rowDetail) {
      const index = rowDetail.schedule.length
      this.oldTarget = {
        isEditable: true,
        hidden: false,
        index,
        actionFileNumber: obj.actionFileNumber,
        subject: obj.subject,
        assignment: {
          assignmentID: 1,
        },
        actionType: obj.actionType,
        caseDueDate: obj.caseDueDate,
        actionID: obj.actionID,
        start: obj.start,
        end: obj.end,
      }
        this.scheduleData.map(row => {
            row.schedule.map(item => {
                item.isEditable = false;
                return item;
            })
        })
      this.scheduleData[this.scheduleData.map(i => i.value).indexOf(rowDetail.value)].schedule.push(this.oldTarget);
    },

    getUserTags(item) {
      const tags = [
        { condition: item.IsSIU, label: "SIU" },
        { condition: item.isNYSIF, label: "NYSIF" },
        { condition: item.IsUSP, label: "USP" },
        { condition: item.IsEDR, label: "EDR" },
        { condition: item.IsNotary, label: "NP" },
        { condition: item.IsHybrid, label: "Hybrid" }
      ];
      return tags
          .filter(tag => tag.condition)
          .map(tag => tag.label)
          .join(", ");
    }
  }
};
</script>

<style scoped>
@import "schedule.css";
</style>